import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { IQuery, IQueryContent, RESPONSE_BY } from '../../models/IQueryType';
import SendMessage from '../openAIChat/SendMessage';
import ChatReply from '../openAIChat/ChatReply';
import InputBox from '../openAIChat/InputBox';
import LABELS from '../../ui-constants/en.json';
import { useDispatch, useSelector } from 'react-redux';
import { HttpStatusCode } from '../../ui-constants/base/enum';
import { IStore } from '../../store';
import {
  setChatHistory,
  setChunkLoading,
  setWhoelChatTable,
  toggleLoader,
  clearDownloadFileMessage,
  setDownloadFileMessageWord,
  setRelevantFiles,
  setChatErrors,
  regenerateChatSummary,
  saveUserChatThreadHistory,
  setLastDeletedThreadID,
  setChatTable,
  setChatSummary
} from '../../store/actions/userChatActions';
import LinkButton from '../LinkButton/LinkButton';
import { downloadImg } from '../../commonImage';
import exportToWord from '../openAIChat/utils/exportToWord';
import './EyIpKcPerspective.scss';
import { getEyIpKcResponse, getQueryResponseEngagement, saveDownloadChatActivity, saveUserChatHistory } from '../../services/chatQuery.service';
import {
  checkOnySpecialCharacter,
  getComputedRequestObject,
  handleSessionID,
  handleUserChatThreadHistory,
  setThreadsUnSelected
} from '../../utils/commonUtils';
import { initializeSession, logoutandClearSession } from '../../services/session.service';
import { MotifToast } from '@ey-xd/motif-react';
import exportEngagementChatToWord from '../openAIChat/utils/exportEngagementChatToWord';
import { setRightSideBarOpenClose } from '../../store/actions/commonActions';
import errorStatus from '../../utils/ErrorStatus';

type IEyIpKcChatProps = {
  chatQueryApi?: string;
  sectors: string[];
  geographies: string[];
  contentage: string;
  handleFocus?: () => void;
};
const EyIpKcPerspective = ({
  chatQueryApi = '',
  sectors,
  geographies,
  contentage,
  handleFocus
}: IEyIpKcChatProps) => {
  const [seQuery, setSeQuery] = useState<IQuery[]>([]);
  const [queryText, setQueryText] = useState<string>('');
  const [isValidQuery, setIsValidQuery] = useState<boolean>(false);

  const refInput = useRef<HTMLTextAreaElement>(null);
  const refToScroll = useRef<HTMLDivElement>(null);
  const { isClearLoading, chatDataSummmary, isLoading, isSideBarOpen, chunkLoading } = useSelector(
    (state: any) => state.userChat
  );
  const userData = useSelector((state: IStore) => state.userProfile?.userData);
  const { isRightSideBarOpen, anySourceSelected } = useSelector((state: IStore) => state.common);
  const [showDownloadInitiated, setsShowDownloadInitiated] = useState(false);
  const fileRegex = new RegExp('relevant_files:', 'i');

  const dispatch = useDispatch();
  const timeoutError = [
    {
      [RESPONSE_BY.STRATEGY_EDGE]: {
        summary: `\n\n\n${LABELS.chat_reply.SE_TOKEN_ERROR_MSG}`,
        content: [],
        responseType: 'error',
        sources: ['EY-P Strategy projects']
      }
    }
  ];
  const replyServerError = [
    {
      [RESPONSE_BY.STRATEGY_EDGE]: {
        summary: `\n\n\n${LABELS.chat_reply.OPENAI_ERROR_MSG}`,
        content: [],
        responseType: 'error',
        sources: ['EY-P Strategy projects ']
      }
    }
  ];
  const replyNotFoundError = [
    {
      [RESPONSE_BY.STRATEGY_EDGE]: {
        summary: `\n\n\n${LABELS.chat_reply.OPENAI_NOT_FOUND_ERROR_MSG}`,
        content: [],
        responseType: 'error',
        sources: ['EY-P Strategy projects']
      }
    }
  ];
  const [chatLoadCompleted, setChatLoadCompleted] = useState(false);
  const [regeneratedMessageId, setRegeneratedMessageId] = useState(0);
  const [firstResponseTime, setFirstResponseTime] = useState(0);
  const [totalResponseTime, setTotalResponseTime] = useState<number|null>();
  const userChatThreads = useSelector((store: IStore) => store.userChat.chatThreadHistory);
  const [currentUserChatThreads, setCurrentUserChatThreads] = useState(userChatThreads);
  const selectedChatThread = useSelector((store: IStore) => store.userChat.selectedChatThread);
  const checkedCheckboxes = useSelector((state: any) => state.common?.checkedCheckboxes);
  const eyIPInterRadio = useSelector((state: IStore) => state?.common?.eyIPInterRadio);
  const validURLinputValue = useSelector((state: IStore) => state?.common?.validURLinputValue);
  const lastDeletedThreadID = useSelector((state: IStore) => state?.userChat?.lastDeletedThreadID);
  const selectedDataSourceSection = useSelector(
    (state: any) => state.userChat.selectedDataSourceSection
  );
  const [controller, setController] = useState<any>(null);
  const [isCompletelyAbortedResposne, setIsCompletelyAbortedResposne] = useState<boolean>(false);
  const [isParitallyAbortedResponse, setIsParitallyAbortedResponse] = useState<boolean>(false);
  useEffect(() => {
    setSeQuery(() => chatDataSummmary?.table);
  }, [chatDataSummmary, chunkLoading]);

  const updateFirstResponseTime = (firstByteTime: any, startTime: number) => {
    if (firstByteTime === null) {
      firstByteTime = performance.now();
      setFirstResponseTime((firstByteTime - startTime) / 1000);
    }
  }

  const updateTotalResponseTime = (startTime: number, isStaticResponse: boolean = false) => {
    if (isStaticResponse) {
      setTotalResponseTime(null);
      return;
    } else {
      const endTime = performance.now(); // Record the end time
      const duration = (endTime - startTime) / 1000; // Calculate the duration
      setTotalResponseTime(duration); // Update state with the response time
    }
  };

  const sendChatQuery = async (summary: string) => {
    setIsCompletelyAbortedResposne(false);
    setIsParitallyAbortedResponse(false);
    scrollToBottom();
    let chunks = '';
    // let isRelevantFiles: boolean = false;
    const controller = new AbortController();
    setController(controller);
    let check = false;
    const startTime = performance.now();
    let firstByteTime : any = null;
    try {
      // chatResponse = '';
      const summaryData = JSON.stringify({
        query: summary.trim(),
        sectors: sectors,
        geographies: [],
        content_ages: contentage
      });

      const response: any = await getEyIpKcResponse(summaryData, userData, controller);
      const { status, statusText } = response;
      if (controller.signal.aborted) {
        const stopGenerationErrorMsg = [{
          [RESPONSE_BY.STRATEGY_EDGE]: {
            summary: LABELS.chat_reply.STOP_GENERATION_MSG,
            content: [],
            responseType: 'stop_generation',
            sources: [],
            isCompletelyAbortedResposne: true,
            isParitallyAbortedResponse: false
          }
        }];
        dispatch(setChatErrors([...stopGenerationErrorMsg]));
        dispatch(toggleLoader(false));
        scrollToBottom();
        setChatLoadCompleted(true);
      }
      else if (
        status === HttpStatusCode.InternalServerError ||
        status === HttpStatusCode.TimedOut ||
        status === HttpStatusCode.BadRequest ||
        statusText === LABELS.SERVER_ERROR
      ) {
        updateFirstResponseTime(firstByteTime, startTime);
        dispatch(setChatErrors([...replyServerError]));
        updateTotalResponseTime(startTime, true);
        dispatch(toggleLoader(false));
        setTimeout(() => {
          return scrollToBottom();
        }, 100);
        setChatLoadCompleted(true);
      } else if (status === HttpStatusCode.NotFound) {
        updateFirstResponseTime(firstByteTime, startTime);
        dispatch(setChatErrors([...replyNotFoundError]));
        updateTotalResponseTime(startTime, true);
        dispatch(toggleLoader(false));
        setTimeout(() => {
          return scrollToBottom();
        }, 100);
        setChatLoadCompleted(true);
      } else {
        updateFirstResponseTime(firstByteTime, startTime);
        const reader = response.body.getReader();
        reader
          .read()
          .then(function pump({ done, value }: any) {
            const decodedChunks = new TextDecoder().decode(value);
            chunks += decodedChunks.replaceAll('\n\n', '\n');
            dispatch(setChunkLoading(true));
            if (!check) {
              dispatch(
                setChatTable([
                  {
                    SEResponse: {
                      Content: [],
                      Sources: [],
                      downloadURL: ''
                    }
                  }
                ])
              );
              if (chunks) dispatch(setChatSummary(decodedChunks));
              check = true;
            } else {
              if (chunks && !chunks.includes('relevant_files:')) {
                dispatch(setChatSummary(decodedChunks));
                scrollToBottom();
              }
            }
            if (done) {
              updateTotalResponseTime(startTime);
              setChatLoadCompleted(true);
              dispatch(setChunkLoading(false));
              dispatch(setWhoelChatTable(true));
              dispatch(toggleLoader(false));
              scrollToBottom();
              setRelevantTableData(chunks);
              chunks = '';
              return;
            }
            return reader.read().then(pump);
          })
          .catch((err: any) => {
            // dispatch(setChatHistory([...replyServerError]));
            // dispatch(setChatErrors([...replyServerError]));
            dispatch(setChunkLoading(false));
            setChatLoadCompleted(true);
            scrollToBottom();
            dispatch(toggleLoader(false));
            updateTotalResponseTime(startTime, true);
          });
      }
    } catch (error) {
      setChatLoadCompleted(true);
      dispatch(setChunkLoading(false));
      if (controller.signal.aborted) {
        dispatch(setChatErrors([...timeoutError]));
        dispatch(toggleLoader(false));
        scrollToBottom();
        updateTotalResponseTime(startTime, true);
      } else {
        dispatch(setChatErrors([...replyServerError]));
        dispatch(toggleLoader(false));
        setChatLoadCompleted(true);
        scrollToBottom();
        updateTotalResponseTime(startTime, true);
      }
    }
  };

  const setRelevantTableData = (str: string) => {
    try {
      const splitwiseFiles = str.split(fileRegex);
      if (splitwiseFiles.length > 1) {
        const filesstring = splitwiseFiles[1];
        const result = JSON.parse(filesstring.replace(/\n/g, '').replace(/\t/g, ''));

        if (result.length) {
          const updatedRes = result.map((res: any) => {
            let pagelist = res.page_number.reduce((newPages: any, page: any) => {
              if (newPages.includes(page)) {
                return newPages;
              }
              return [...newPages, page];
            }, []);
            return { ...res, page_number: pagelist };
          });
          dispatch(setRelevantFiles(updatedRes));
        }
      }
    } catch (er: any) {
      return errorStatus(er?.response?.status);
    }
  };

  const handleSendMessage = (
    ev: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLTextAreaElement>,
    query?: string
  ) => {
    if (!isClearLoading && !isLoading) {
      if ((queryText && queryText.trim().length) || query) {
        handleSessionID(selectedChatThread);
        setRegeneratedMessageId(0);
        let newMsg = {
          [RESPONSE_BY.USER]: {
            summary: queryText || query
          }
        };
        dispatch(setChatHistory([newMsg]));
        refToScroll?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        });
        sendChatQuery((queryText || query || '').trim());
        setQueryText('');
        dispatch(toggleLoader(true));
        if(Object.keys(selectedChatThread).length === 0 && !localStorage.isSidebarManuallyClosed){
          dispatch(setRightSideBarOpenClose(true));
        }
      }
    }
  };

  const handleRegenerate = (
    ev: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLTextAreaElement>,
    query?: string
  ) => {
    if (!isClearLoading && !isLoading) {
      if ((queryText && queryText.trim().length) || query) {
        handleSessionID(selectedChatThread);
        let newMsg = {
          [RESPONSE_BY.USER]: {
            summary: queryText || query
          }
        };
        setRegeneratedMessageId(
          chatDataSummmary?.table?.[chatDataSummmary?.table?.length - 1]?.SEResponse?.messageId ||
            regeneratedMessageId
        );
        dispatch(regenerateChatSummary([newMsg]));
        dispatch(setChatHistory([newMsg]));
        sendChatQuery((queryText || query || '').trim());
        setQueryText('');
        dispatch(toggleLoader(true));
      }
    }
  };

  const scrollToBottom = () => {
    refToScroll?.current?.scroll({
      left: 0,
      top: refToScroll.current.scrollHeight,
      behavior: 'smooth'
    });
  };

  const handleScrollToBottom = () => {
    scrollToBottom();
  };

  const handleOnChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (checkOnySpecialCharacter(ev.target.value)) setIsValidQuery(false);
    else {
      setIsValidQuery(true);
    }
    setQueryText(ev.target.value);
  };

  useEffect(() => {
    initializeSession();
  }, []);

  useEffect(() => {
    refInput.current?.focus();
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }, [seQuery]);

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      handleSendMessage(ev);
    }
  };

  useEffect(() => {
    if (chatQueryApi?.length > 0 && Object.keys(selectedChatThread).length === 0) {
      sendChatQuery(chatQueryApi);
    }
  }, [chatQueryApi]);

  useEffect(() => {
    setCurrentUserChatThreads(userChatThreads);
  }, [userChatThreads]);

  useEffect(() => {
    if (lastDeletedThreadID != 0) {
      const updated = userChatThreads.filter((item: any) => item.threadId !== lastDeletedThreadID);
      setCurrentUserChatThreads(updated);
      dispatch(saveUserChatThreadHistory(updated));
      dispatch(setLastDeletedThreadID(0));
    }
  }, [lastDeletedThreadID]);

  const handleDocDownload = async () => {
    dispatch(setDownloadFileMessageWord());
    setsShowDownloadInitiated(true);
    await exportToWord(
      seQuery,
      userData.profileImage,
      RESPONSE_BY.PESPECTIVE,
      checkedCheckboxes,
      eyIPInterRadio,
      validURLinputValue
    );
    dispatch(clearDownloadFileMessage());
    saveDownloadChatActivity('EY-P Strategy projects');
    setTimeout(() => {
      setsShowDownloadInitiated(false);
  }, 1000);
  };

  useEffect(() => {
    if (chatLoadCompleted) saveChatConversation();
  }, [chatLoadCompleted]);

  const saveChatConversation = async () => {
    if (chatDataSummmary?.table?.length === 0) return;
    let requestObject = getComputedRequestObject(
      selectedChatThread,
      regeneratedMessageId,
      chatDataSummmary,
      checkedCheckboxes,
      eyIPInterRadio,
      validURLinputValue,
      firstResponseTime,
      totalResponseTime,
      false,
      isCompletelyAbortedResposne,
      isParitallyAbortedResponse,
      selectedDataSourceSection
    );
    const response: any = await saveUserChatHistory(requestObject);
    if (response && response?.threadId) {
      if (response.threadId !== selectedChatThread?.threadId) {
        setThreadsUnSelected(currentUserChatThreads);
        handleUserChatThreadHistory(
          requestObject,
          response.threadId,
          currentUserChatThreads,
          userData,
          dispatch
        );
      }
    }
    setRegeneratedMessageId(response?.messageId);
    setChatLoadCompleted(false);
  };

  const handleStopGeneration = () => {
    controller.abort();
    setIsCompletelyAbortedResposne(false);
    setIsParitallyAbortedResponse(false);
    if(chunkLoading) {
      setIsParitallyAbortedResponse(true);
      let lastChatData = chatDataSummmary?.table[chatDataSummmary?.table?.length - 1]?.SEResponse || undefined;
      if (lastChatData) {
        if (!("isCompletelyAbortedResposne" in lastChatData) && !("isParitallyAbortedResponse" in lastChatData)) {
          lastChatData.isCompletelyAbortedResposne = false;
          lastChatData.isParitallyAbortedResponse = true;
        }
      }
      setChatLoadCompleted(true);
    } 
    else {
      setIsCompletelyAbortedResposne(true);
    }
  }

  const getResponseFlag = (SEResponse: any, flag: string) => {
    if(flag in SEResponse) {
      return SEResponse[flag];
    } else {
      return false;
    }
  };

  return (
    <>
      <div
        className={'kcEyIpChatContainer'}
        // style={{
        //   display: isRightSideBarOpen ? 'flex' : 'block'
        // }}
        >
        <div
          ref={refToScroll}
          className='kcEyIpChatContainer__scroll-container'
          // style={{
          //   minHeight: seQuery?.length > 0 ? 160 : 0
          // }}
          >
          {seQuery?.map((chat: any, ind: number) => {
            const { user, SEResponse } = chat;

            return (
              <React.Fragment key={ind}>
                {showDownloadInitiated && (
                  <MotifToast
                    className={'kcEyIpChatContainer__toast-container-public'}
                    variant='info'
                    actionName=''
                    position='bottom'
                    onClose={() => {
                      setsShowDownloadInitiated(false);
                    }}>
                    <div>Downloading</div>
                  </MotifToast>
                )}

                {user && (
                  <SendMessage
                    key={ind}
                    message={user}
                    isNew={true}
                    userQueryWithResponse={chat}
                    loader={isLoading}
                    chatData={{ SEResponse }}
                    isEngagement={false}
                    handleSendMessage={handleSendMessage}
                  />
                )}

                {SEResponse && (
                  <ChatReply
                    message={user?.summary}
                    chatData={{ SEResponse }}
                    key={ind.toString() + '__strctrd-se-chats'}
                    isNew={true}
                    isSE={true}
                    messageLoading={isLoading}
                    chunkLoading={chunkLoading}
                    isLastItem={ind === seQuery.length - 1}
                    scrollToBottom={handleScrollToBottom}
                    handleRegenerate={handleRegenerate}
                    isCompletelyAbortedResposne={getResponseFlag(SEResponse, "isCompletelyAbortedResposne")}
                    isParitallyAbortedResponse={getResponseFlag(SEResponse, "isParitallyAbortedResponse")}
                    // isKCEngagement={true}
                    isEngagement={false}
                  />
                )}
              </React.Fragment>
            );
          })}
          {isLoading && !chunkLoading && <ChatReply loader={isLoading} isNew={true} isSE={true} />}
          <div className="stop-generation-container">
            {(isLoading || chunkLoading) && <button onClick={handleStopGeneration} className='stop-generation-button'>Stop Generating</button>}
          </div>
        </div>
      </div>
      <div className='kcEyIpChatContainer__input-container'>
        <InputBox
          selectedSegment={true}
          queryText={queryText}
          refInput={refInput}
          hasQueries={seQuery?.length > 0}
          disabled={isLoading}
          isEngagement={false}
          handleOnChange={handleOnChange}
          handleResetMessage={() => {}}
          handleSendMessage={handleSendMessage}
          handleKeyDown={handleKeyDown}
          handleOnFocus={handleFocus}
        />
        <div className='kcEyIpChatContainer__download-btn-box'>
          <LinkButton onClick={() => handleDocDownload()} disabled={isLoading || isClearLoading}>
            <img src={downloadImg} /> &nbsp;
            <span className='kcEyIpChatContainer__download-text'>Download Chat</span>
          </LinkButton>
        </div>
      </div>
    </>
  );
};
export default EyIpKcPerspective;
